
.my-resume
{
    height: 1000px;
    width: 60%;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    display: block
}
