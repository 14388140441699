
.header-contents
{
  display: flex;
  align-items: center;
  color: black;
}

@media screen and (max-width: 1250px) {
    .header-contents {
      margin-left: auto;
      margin-right: auto;
      position: relative;
      display: block
    }
}

.my-headshot
{
  margin: 1%;
  display: block;
}

.my-headshot img
{
  border-radius: 5%;
  border: 2px solid gray;
}

@media screen and (max-width: 1250px) {
    .my-headshot {
      margin-left: auto;
      margin-right: auto;
      position: relative;
      display: block;
      margin: 0%;
    }
}

@media screen and (max-width: 1250px) {
    .my-headshot img {
      margin-left: auto;
      margin-right: auto;
      position: relative;
      display: block;
    }
}

.header-name
{
  margin-top: 0.5rem;
  text-align: center;
}

.nav-buttons {
  display: block
}

.nav-buttons ul {
  margin: 0 auto 0;
  padding: 0;
  list-style: none;
  display: table;
  text-align: center;
}
.nav-buttons li {
  display: inline-block;
  position: relative;
  padding: 15px 0;
}
.nav-buttons a {
  color: black;
  text-transform: uppercase;
  text-decoration: none;
  letter-spacing: 0.15em;
  display: inline-block;
  padding: 15px 20px;
  position: relative;
}
.nav-buttons a:after {
  background: none repeat scroll 0 0 transparent;
  bottom: 0;
  bottom: -3px;
  content: "";
  display: block;
  height: 3px;
  left: 50%;
  position: absolute;
  background: black;
  transition: width 0.3s ease 0s, left 0.3s ease 0s;
  width: 0;
}
.nav-buttons a:hover:after {
  width: 100%;
  left: 0;
}

.selected-nav-button {
  border-bottom: 3px solid black;
}

.non-selected-nav-button {
  border: none;
}

@media screen and (max-height: 300px) {
	ul {
		margin-top: 40px;
	}
}
